import { dependencies } from '@pn/core/dependencies';
import type { ProductionChartType } from '@pn/core/domain/production';
import { useInjectableReducer } from '@pn/core/storage/injectableReducers';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import type { IProductionActions, IProductionStorage } from './ports';
import { createProductionSlice } from './productionSlice';

export const useReduxProductionStorage = (
  productionChartType: ProductionChartType
): IProductionStorage => {
  const { name, getInitialState, reducer } =
    createProductionSlice(productionChartType);

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  useInjectableReducer(name, reducer);

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isFetchingExtra: useSliceSelector((state) => state.isFetchingExtra),
    isError: useSliceSelector((state) => state.isError),
    productionItems: useSliceSelector((state) => state.productionItems),
    idsLoaded: useSliceSelector((state) => state.idsLoaded),
  };
};

export const reduxProductionActions = (
  productionChartType: ProductionChartType
): IProductionActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = createProductionSlice(productionChartType);

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    requestExtra: () => dispatch(actions.requestExtra()),
    error: () => dispatch(actions.error()),
    receive: (productionItems) => dispatch(actions.receive(productionItems)),
    prepend: (productionItems) => dispatch(actions.prepend(productionItems)),
    receiveExtra: (productionItems) =>
      dispatch(actions.receiveExtra(productionItems)),
    reset: () => dispatch(actions.reset()),
  };
};
