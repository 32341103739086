import { isEditable } from '@pn/core/permissions/editability';
import {
  projectsActions,
  useCurrentUserStorage,
  useProjectsStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { isSpecialProject } from '@pn/core/storage/workspace/defaultWorkspaces';
import { arraysAreIdentical } from '@pn/core/utils/logic';
import React from 'react';

export function useAutoUpdateProject() {
  const { isFetching, workspaceItems } = useWorkspaceStorage();
  const { selectedProject } = useProjectsStorage();
  const { user } = useCurrentUserStorage();

  const editable = isEditable(selectedProject, user);

  React.useEffect(() => {
    if (
      isFetching ||
      !editable ||
      isSpecialProject(selectedProject) ||
      selectedProject.origin !== 'pn'
    )
      return;

    const nonTemporaryIds = workspaceItems
      .filter((item) => !item.isTemporary)
      .map((item) => item.id);

    if (
      !arraysAreIdentical(selectedProject.workspaceItemIds, nonTemporaryIds)
    ) {
      console.log('Auto-saving project', nonTemporaryIds);
      projectsActions().update({
        ...selectedProject,
        workspaceItemIds: nonTemporaryIds,
      });
    }
  }, [isFetching, editable, selectedProject, workspaceItems]);
}
