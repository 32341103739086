import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import type { Project } from '@pn/core/domain/project';
import { handleError } from '@pn/core/errors/handleError';
import { useAccess } from '@pn/core/permissions/access';
import { isEditable } from '@pn/core/permissions/editability';
import { useCurrentUserStorage, useProjectsStorage } from '@pn/core/storage';
import { isDefaultProject } from '@pn/core/storage/workspace/defaultWorkspaces';
import { useConfirmationDialog } from '@pn/ui/context-components/ConfirmationDialog';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { useMenu } from '@pn/ui/hooks/useMenu';
import { useProjectState } from 'src/ui/workspace/project-controls/ProjectStateProvider';
import { useProjectActions } from 'src/ui/workspace/project-controls/useProjectActions';
import { useShareDialog } from 'src/ui/workspace/share/ShareDialogProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  button: {
    minWidth: 40,
    height: 40,
    padding: 0,
  },
  projectName: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  warning: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
}));

type Props = {
  project: Project;
};

export const ProjectActionButton = ({ project }: Props) => {
  const { classes, theme } = useStyles();

  const { user } = useCurrentUserStorage();
  const { isProjectLoaded } = useProjectsStorage();

  const access = useAccess();
  const isAccessDenied = access('projects').denied();

  const { handleResetProject, handleStartCreation, handleStartRenaming } =
    useProjectState();
  const { inProgress, duplicateProject, deleteProject } = useProjectActions();
  const { openShareDialog } = useShareDialog();
  const { handleOpenConfirmationDialog } = useConfirmationDialog();

  const {
    anchorEl,
    handlers: { openMenu, closeMenu },
  } = useMenu();

  const isEditingDisabled = inProgress || !isEditable(project, user);

  const handleShare = () => {
    closeMenu();
    openShareDialog('project', project.id);
  };

  const handleDuplicate = async () => {
    try {
      await duplicateProject(project);
      handleStartRenaming();
    } catch (error) {
      handleError({
        error,
        userFriendlyMessage: 'Failed to recreate this project',
      });
    }
  };

  const handleDeleteWithConfirmation = () => {
    closeMenu();

    handleOpenConfirmationDialog({
      title: 'Delete project?',
      text: (
        <Typography>
          Are you sure you want to delete{' '}
          <span className={classes.projectName}>{project.name}</span>? This
          action cannot be undone.
        </Typography>
      ),
      action: () => {
        deleteProject(project);
        handleResetProject();
      },
    });
  };

  if (isDefaultProject(project)) {
    return (
      <CustomButton
        className={classes.button}
        title="Save as new project"
        disabled={isAccessDenied}
        onClick={handleStartCreation}
      >
        {isAccessDenied ? (
          <LockIcon sx={{ fontSize: 20 }} />
        ) : (
          <SaveIcon sx={{ fontSize: 20 }} />
        )}
      </CustomButton>
    );
  }

  return (
    <>
      <CustomButton
        className={classes.button}
        title="Actions"
        disabled={!isProjectLoaded}
        onClick={openMenu}
      >
        <MoreVertIcon sx={{ fontSize: 20 }} />
      </CustomButton>

      <Menu
        marginThreshold={parseInt(theme.spacing(1.5))}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        slotProps={{
          paper: {
            sx: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        <MenuItem disabled={inProgress} onClick={handleShare}>
          <ListItemIcon>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem>
        <MenuItem disabled={isEditingDisabled} onClick={handleStartRenaming}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem disabled={inProgress} onClick={handleDuplicate}>
          <ListItemIcon>
            <CopyAllIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
          {inProgress && <CircularProgress size={18} />}
        </MenuItem>
        <MenuItem
          disabled={isEditingDisabled}
          onClick={handleDeleteWithConfirmation}
        >
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
