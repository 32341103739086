import InfoIcon from '@mui/icons-material/Info';
import SyncIcon from '@mui/icons-material/Sync';
import {
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useConvertToStack } from '@pn/core/operations/stackdx/convertToStack';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  listItemButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  iconCircularProgress: {
    marginLeft: 10,
    marginRight: 10,
  },
  syncText: {
    color: theme.palette.info.main,
  },
  primaryText: {
    display: 'inline-flex',
    gap: theme.spacing(1),
  },
}));

type Props = {
  disabled: boolean;
  item: WorkspaceItem;
};

export function ConvertToStackButton({ disabled, item }: Props) {
  const { classes } = useStyles();

  const { isConverting, convertToStack } = useConvertToStack({ item });
  const { openWorkspaceItemPanel } = useWorkspaceItemPanel();

  const isDisabled = disabled || isConverting;

  const handleSyncWithStack = async () => {
    const newItem = await convertToStack();
    if (!isNil(newItem)) openWorkspaceItemPanel({ item: newItem });
  };

  return (
    <ListItemButton
      disableGutters
      disabled={isDisabled}
      className={classes.listItemButton}
      onClick={handleSyncWithStack}
    >
      <ListItemIcon className={classes.syncText}>
        {isConverting ? (
          <CircularProgress
            size={20}
            color="info"
            className={classes.iconCircularProgress}
          />
        ) : (
          <SyncIcon color="info" className={classes.icon} />
        )}
      </ListItemIcon>
      <ListItemText
        className={classes.syncText}
        primary={
          <span className={classes.primaryText}>
            Convert to a StackDX project{' '}
            {item.dataType === 'wells' && (
              <Tooltip
                title="Only Stack Wells will be converted"
                placement="right"
              >
                <InfoIcon
                  fontSize="small"
                  color="action"
                  sx={{ position: 'relative', top: -1 }}
                />
              </Tooltip>
            )}
          </span>
        }
      />
    </ListItemButton>
  );
}
