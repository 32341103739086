import { createProject, type Project } from '@pn/core/domain/project';
import { handleError } from '@pn/core/errors/handleError';
import {
  projectsActions,
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { defaultProject } from '@pn/core/storage/workspace/defaultWorkspaces';
import { apiProjectMapper } from '@pn/services/api/project/apiProjectMapper';
import assert from 'assert';
import { last } from 'lodash-es';
import React from 'react';
import { apiClient, notify } from 'src/application/externalDependencies';
import { useAutoUpdateProject } from 'src/ui/workspace/project-controls/useAutoUpdateProject';

export enum ProjectState {
  Selector,
  CreateInput,
  EditInput,
}

const ProjectStateContext = React.createContext<{
  projectState: ProjectState;
  handleSelectProject: (project: Project) => void;
  handleResetProject: () => void;
  handleStartCreation: () => void;
  handleStartRenaming: () => void;
  handleCancel: () => void;
  handleCreateProject: (name: string) => Promise<void>;
  handleUpdateProject: (project: Project, name: string) => Promise<void>;
}>({} as any);

export const useProjectState = () => React.useContext(ProjectStateContext);

type Props = {
  children: React.ReactNode;
};

export const ProjectStateProvider = ({ children }: Props) => {
  const { workspaceItems } = useWorkspaceStorage();
  const { user } = useCurrentUserStorage();

  useAutoUpdateProject();

  const [state, setState] = React.useState(ProjectState.Selector);

  const handleSelectProject = (project: Project) => {
    setState(ProjectState.Selector);

    projectsActions().select(project.id);
    workspaceActions().setWorkspace(project.workspaceItemIds);
    workspaceActions().select(last(project.workspaceItemIds));

    project.workspaceItemIds.forEach((itemId) =>
      workspaceActions().revisualize(itemId)
    );
  };

  const handleResetProject = () => handleSelectProject(defaultProject);

  const handleStartCreation = () => {
    setState(ProjectState.CreateInput);
  };

  const handleStartRenaming = () => {
    setState(ProjectState.EditInput);
  };

  const handleCancel = () => {
    setState(ProjectState.Selector);
  };

  const handleCreateProject = async (name: string) => {
    assert(user, 'User must be defined to create projects');

    try {
      const project = createProject(
        {
          name,
          workspaceItemIds: workspaceItems
            .filter((item) => !item.isTemporary)
            .map((item) => item.id),
        },
        user
      );

      const { id } = await apiClient.request<{ id: string }>({
        method: 'POST',
        url: 'v2/projects',
        payload: apiProjectMapper.toTargetProjectPayload({ ...project, name }),
      });

      projectsActions().create({ ...project, id });
      projectsActions().select(id);

      setState(ProjectState.Selector);

      notify('Project created!');
    } catch (error) {
      handleError({ error, userFriendlyMessage: 'Failed to create project' });
    }
  };

  const handleUpdateProject = async (project: Project, name: string) => {
    setState(ProjectState.Selector);

    projectsActions().update({ ...project, name });
  };

  return (
    <ProjectStateContext.Provider
      value={{
        projectState: state,
        handleSelectProject,
        handleResetProject,
        handleStartCreation,
        handleStartRenaming,
        handleCancel,
        handleCreateProject,
        handleUpdateProject,
      }}
    >
      {children}
    </ProjectStateContext.Provider>
  );
};
