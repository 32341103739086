import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useProjectState } from 'src/ui/workspace/project-controls/ProjectStateProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  button: {
    minWidth: 40,
    height: 40,
    padding: 0,
  },
}));

export const ResetButton = () => {
  const { classes } = useStyles();

  const { handleResetProject } = useProjectState();

  return (
    <Button
      variant="contained"
      color="inherit"
      className={classes.button}
      title="Reset workspace"
      onClick={handleResetProject}
    >
      <CloseIcon sx={{ fontSize: 20 }} />
    </Button>
  );
};
