import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
} from '@mui/material';
import { getFullName, UserPlans } from '@pn/core/domain/user';
import { getWorkspaceItemUrl } from '@pn/core/domain/workspace';
import { useCurrentUserStorage } from '@pn/core/storage';
import { NotificationTooltip } from '@pn/ui/custom-components/NotificationTooltip';
import { useCopyToClipboard } from '@pn/ui/hooks/useCopyToClipboard';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';
import type { Shareable } from 'src/ui/workspace/share/ShareDialogProvider';
import { makeStyles } from 'tss-react/mui';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import { isNil } from 'lodash-es';

const useStyles = makeStyles()((theme) => ({
  dialog: {
    width: 480,
  },
  listItem: {
    height: 52,
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  iconButton: {
    padding: theme.spacing(1.25),
  },
  link: {
    cursor: 'pointer',
  },
}));

type Props = {
  open: boolean;
  shareable: Shareable;
  onClose: () => void;
  onToggleShared: (checked: boolean) => void;
};

export const ShareDialog = ({
  shareable,
  open,
  onClose,
  onToggleShared,
}: Props) => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();

  const { openLibrary, setLibraryRouter } = useLibrary();

  const { copyText } = useCopyToClipboard();

  const isAuthoredByCurrentUser = shareable.author?.id === user?.id;
  const isEnterpriseAdmin = user?.userPlan === UserPlans.EnterpriseAdmin;
  const shareUrl = getShareUrl(shareable);

  const canAddToSharedFolder = isEnterpriseAdmin && !shareable.isGlobal;

  const handleOpenSharedFolder = () => {
    onClose();

    setLibraryRouter({
      section: shareable.type === 'project' ? 'projects' : 'items',
      folder: 'Shared',
    });
    openLibrary();
  };

  return (
    <Dialog
      fullWidth
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        {!shareable.isGlobal && (
          <DialogContentText>
            Any member of your company with the link can view but not edit this{' '}
            {getShortType(shareable.type)}.
          </DialogContentText>
        )}

        <List dense>
          {!isAuthoredByCurrentUser && (
            <ListItem
              disableGutters
              disablePadding
              className={classes.listItem}
            >
              <ListItemIcon>
                {isNil(shareable.author) ? (
                  <PublicIcon className={classes.icon} />
                ) : (
                  <PersonIcon className={classes.icon} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={`Created by ${
                  isNil(shareable.author)
                    ? 'Petro Ninja'
                    : getFullName(shareable.author)
                }`}
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItem>
          )}

          <ListItem disableGutters disablePadding className={classes.listItem}>
            <ListItemIcon>
              <NotificationTooltip
                title="Copied URL to clipboard"
                placement="left"
              >
                <IconButton
                  className={classes.iconButton}
                  color="info"
                  onClick={() => copyText(shareUrl)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </NotificationTooltip>
            </ListItemIcon>
            <ListItemText primary={shareUrl} />
          </ListItem>

          <ListItem disableGutters disablePadding className={classes.listItem}>
            <ListItemIcon>
              <Switch
                size="small"
                color="info"
                disabled={!canAddToSharedFolder}
                checked={shareable.isShared}
                onChange={(event) => onToggleShared(event.target.checked)}
              />
            </ListItemIcon>
            <ListItemText
              primary={`Share with everyone in ${user?.enterprise?.name}`}
              primaryTypographyProps={{
                color: canAddToSharedFolder ? 'textPrimary' : 'textSecondary',
              }}
            />
          </ListItem>
        </List>

        <Alert severity={isEnterpriseAdmin ? 'info' : 'warning'}>
          Enterprise administrators can add {getShortType(shareable.type)}s to
          the
          <br />
          <Link className={classes.link} onClick={handleOpenSharedFolder}>
            {shareable.type === 'project' ? 'Projects' : 'Layers'} &#8250;
            Shared
          </Link>{' '}
          folder for everyone in their company.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

function getShareUrl(shareable: Shareable): string {
  switch (shareable.type) {
    case 'project':
      return (
        window.location.origin +
        '?projectId=' +
        encodeURIComponent(shareable.id)
      );
    default:
      return getWorkspaceItemUrl(
        shareable.id,
        shareable.type,
        window.location.origin
      );
  }
}

function getShortType(type: Shareable['type']): string {
  switch (type) {
    case 'project':
      return 'project';
    case 'annotation':
    case 'drawing':
      return 'annotation';
    default:
      return 'layer';
  }
}
