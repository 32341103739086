import LockIcon from '@mui/icons-material/Lock';
import SyncIcon from '@mui/icons-material/Sync';
import { Alert, Box, Link } from '@mui/material';
import { isEditable } from '@pn/core/permissions/editability';
import { useCurrentUserStorage, useProjectsStorage } from '@pn/core/storage';
import { isSpecialProject } from '@pn/core/storage/workspace/defaultWorkspaces';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    gridArea: 'project-alert',
    padding: theme.spacing(1.5),
    paddingTop: 0,
  },
  alert: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
  },
}));

export const SelectedProjectAlert = () => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { selectedProject } = useProjectsStorage();

  if (isSpecialProject(selectedProject)) return null;

  if (isEditable(selectedProject, user)) {
    return (
      <Box className={classes.container}>
        <Alert severity="info" icon={<SyncIcon />} className={classes.alert}>
          All changes to your project are saved automatically.{' '}
          <Link
            href="https://help.petroninja.com/projects/overview"
            target="_blank"
          >
            Learn more
          </Link>
        </Alert>
      </Box>
    );
  } else {
    return (
      <Box className={classes.container}>
        <Alert
          severity="warning"
          icon={<LockIcon sx={{ fontSize: 22 }} />}
          className={classes.alert}
        >
          Read-only project. Duplicate to make your changes.{' '}
          <Link
            href="https://help.petroninja.com/projects/overview"
            target="_blank"
          >
            Learn more
          </Link>
        </Alert>
      </Box>
    );
  }
};
