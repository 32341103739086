import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { projectsActions, workspaceActions } from '@pn/core/storage';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiStackProjectDetailsMapper } from '@pn/services/api/stackdx/apiStackProjectDetailsMapper';
import { getStackProjectDetails } from '@pn/services/api/stackdx/apiStackProjectDetailsProvider';
import { apiStackWorkspaceItemToWorkspaceItemMapper } from '@pn/services/api/stackdx/apiStackWorkspaceItemMapper';
import React from 'react';

type Props = {
  item: WorkspaceItem;
};

export function useConvertToStack({ item }: Props) {
  const [isConverting, setIsConverting] = React.useState(false);

  const convertToStack = async (): Promise<WorkspaceItem | undefined> => {
    setIsConverting(true);

    try {
      const { stack_project_id } = await pnApiClient.request<{
        stack_project_id: string;
      }>({
        method: 'POST',
        url: `v2/lists/${item.id}/stack`,
      });

      const stackProjectDetails =
        await getStackProjectDetails(stack_project_id);

      const project =
        apiStackProjectDetailsMapper.toDomainProject(stackProjectDetails);

      const newList =
        apiStackWorkspaceItemToWorkspaceItemMapper().toWorkspaceItem(
          stackProjectDetails,
          item.sourceItem
        );

      projectsActions().create(project);

      workspaceActions().remove(item); // remove PN list
      workspaceActions().add(newList);
      workspaceActions().addToWorkspace(newList.id);
      workspaceActions().select(newList.id);

      return newList;
    } catch (error) {
      handleError({
        error,
        userFriendlyMessage: 'Conversion failed',
      });
    }

    setIsConverting(false);

    return undefined;
  };

  return { isConverting, convertToStack };
}
