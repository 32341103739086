import {
  ProductionChartType,
  ProductionItem,
} from '@pn/core/domain/production';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataItemId } from '@pn/core/domain/data';
import { isNil } from 'lodash-es';

type State = {
  isFetching: boolean;
  isFetchingExtra: boolean;
  isError: boolean;
  productionItems: ProductionItem[];
  idsLoaded: DataItemId[];
};

const initialState: State = {
  isFetching: false,
  isFetchingExtra: false,
  isError: false,
  productionItems: [],
  idsLoaded: [],
};

export const createProductionSlice = (
  productionChartType: ProductionChartType
) =>
  createSlice({
    name: `${productionChartType}-production`,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
      },
      requestExtra: (state) => {
        state.isFetchingExtra = true;
      },
      error: (state) => {
        state.isFetching = false;
        state.isFetchingExtra = false;
        state.isError = true;
        state.productionItems = [];
        state.idsLoaded = [];
      },
      receive: (state, { payload }: PayloadAction<ProductionItem[]>) => {
        state.isFetching = false;
        state.isError = false;
        state.productionItems = payload;
        state.idsLoaded = getUniqueIds(payload);
      },
      prepend: (state, { payload }: PayloadAction<ProductionItem[]>) => {
        state.isFetching = false;
        state.isError = false;
        state.productionItems = [...payload, ...state.productionItems];
        state.idsLoaded = getUniqueIds(payload).concat(state.idsLoaded);
      },
      receiveExtra: (state, { payload }: PayloadAction<ProductionItem[]>) => {
        state.isFetchingExtra = false;
        state.productionItems = [...state.productionItems, ...payload];
        state.idsLoaded = state.idsLoaded.concat(getUniqueIds(payload));
      },
      reset: (state) => {
        state.isFetching = false;
        state.isFetchingExtra = false;
        state.isError = false;
        state.productionItems = [];
        state.idsLoaded = [];
      },
    },
  });

function getUniqueIds(productionItems: ProductionItem[]): DataItemId[] {
  const uniqueIds = new Set<DataItemId>();

  productionItems.forEach((productionItem) => {
    if (!isNil(productionItem.id)) uniqueIds.add(productionItem.id);
  });

  return Array.from(uniqueIds);
}
